import React, { useEffect, useState, useCallback } from 'react';
import TextField from '@material-ui/core/TextField';
import label from 'src/utils/label';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { makeStyles } from '@material-ui/core/styles';
import { green } from "@material-ui/core/colors";

const useStyles = makeStyles((theme) => ({
    root: {
        '& .MuiTextField-root': {
            margin: theme.spacing(1),
        },
        '& label.Mui-focused': {
            color: green[600],
        },
        '& .MuiInput-underline:after': {
            borderBottomColor: green[400],
        },
        '& .MuiOutlinedInput-root': {
            '& fieldset': {
                borderColor: green[400],
            },
            '&:hover fieldset': {
                borderColor: green[400],
            },
            '&.Mui-focused fieldset': {
                borderColor: green[600],
            },
        },
    }
}));

const TextArea = ({ ro, getComments, handleChange, questionId, answer }) => {
    const classes = useStyles();
    const [value, setValue] = useState('');
    const handleOnChange = useCallback(e => {
        let questionIds = [1319], numberQuestionFound = false;
        numberQuestionFound = questionIds.some(id => id === questionId);
        let maxLen = 12;
        if ((numberQuestionFound) && (e.target.value.length > maxLen || !/(^$|^\d*$)/.test(e.target.value))) {
            return;
        }
        else {
            setValue(e.target.value);
            handleChange(e);
        }
    }, [handleChange]);
    useEffect(() => {
        setValue(getComments(questionId, answer.id));
    }, [getComments, questionId, answer]);
    return (
        <FormControlLabel
            className={classes.root}
            control={<TextField
                fullWidth
                label={[1025, 1026, 1030, 1032, 1033, 1034, 1037, 1038, 1039, 1041, 1044, 1047, 1050, 1055, 1057, 1059, 1067, 1068].includes(questionId) ? '' : label(answer.label)}
                id={questionId + '_' + answer.id}
                multiline rows={[1319].includes(questionId)? 1 : 4}
                variant="outlined"
            />}
            value={value}
            onChange={handleOnChange}
            disabled={JSON.parse(ro)}
        />
    )
}


export default TextArea;